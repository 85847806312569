import { Grid, Typography } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import EastIcon from "@mui/icons-material/East";
import { useEffect, useState } from "react";
import { BLOG } from "../../../interface/Interface";
import Image from "next/image";
import Link from "next/link";

function Blogs({ relBlogs, heading }: { relBlogs?: BLOG[]; heading: string }) {
  const [blogs, setBlogs] = useState<BLOG[]>([]);

  useEffect(() => {
    if (relBlogs) {
      setBlogs(relBlogs);
      return;
    }
    getBlogData();
  }, [relBlogs]);

  const getBlogData = async () => {
    const RES = await fetch(
      `https://gromo.in/blog/ghost/api/v3/content/posts/?key=e5313c1e06e48b7a7a590f1b1d&include=tags,authors&limit=3`
    ).then((res) => res.json());
    setBlogs(RES.posts);
  };

  return (
    <Grid
      container
      sx={{
        minHeight: "40vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: "50px 30px", md: "60px 0" },
        backgroundColor: "#F4F7FF",
        borderRadius: relBlogs && blogs.length ? "15px" : "",
        margin: relBlogs && blogs.length ? "12px 0" : "",
      }}
    >
      <Grid
        sx={{
          height: "80%",
          width: { xs: "100%", md: "85%" },
          maxWidth: "1440px",
        }}
      >
        <>
          <Typography
     
            textAlign="center"
            fontWeight="700"
            sx={{ fontSize: { xs: "24px", md: "40px" } }}
          >
            {heading}
          </Typography>
        </>
        {/* Cards - CONTAINER*/}

        <Grid
          sx={{
            display: { xs: "none", md: "flex" },
          }}
          style={{
            justifyContent: "space-between",
            alignContent: "center",
            flexWrap: "wrap",
          }}
        >
          {/* Cards */}
          {blogs && blogs.length
            ? blogs.map((blog, i) => {
                return (
                  <Grid
                    key={blog.id}
                    sx={{
                      maxHeight: "380px",
                      width: {
                        xs: "100%",
                        md: relBlogs && blogs.length ? "300px" : "352px",
                      },
                      display: "flex",
                      margin: "20px 0px",
                      flexDirection: "column",
                      cursor: "pointer",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
                      justifyContent: "space-between",
                    }}
                    onClick={() =>
                      window.open("https://gromo.in/blog/" + blog.slug)
                    }
                    className="blogCard"
                  >
                    {/* <Link href={"/blog/" + blog.slug}> */}
                    <Grid
                      style={{
                        width: "100%",
                      }}
                      onClick={() =>
                        window.open("https://gromo.in/blog/" + blog.slug)
                      }
                    >
                      <Image
                        src={blog.feature_image}
                        width={relBlogs && blogs.length ? 300 : 376}
                        height={190}
                        style={{
                          height: "190px",
                          width: "376px",
                          objectFit: "contain",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                        }}
                        loading="lazy"
                        alt={blog.custom_excerpt || "GroMo"}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        width: "100%",
                        padding: { xs: "12px" },
                        minHeight: "190px",
                      }}
                    >
                      <Typography color="#6C717F">
                        {blog.primary_tag
                          ? blog.primary_tag.name.toUpperCase()
                          : "Saving Account"}
                      </Typography>
                      <Typography
               
                        fontWeight="bold"
                        sx={{ fontSize: { xs: "20px", md: "24px" } }}
                      >
                        {blog.title}
                      </Typography>
                    </Grid>
                    {/* </Link> */}
                  </Grid>
                );
              })
            : null}
        </Grid>

        <Grid
          sx={{ display: { xs: "flex", md: "none" }, justifyContent: "center" }}
        >
          {blogs && blogs.length ? (
            <Grid
              sx={{
                maxHeight: "300px",
                width: { xs: "100%", md: "300px" },
                maxWidth: { xs: "300px", md: "" },
                display: "flex",
                margin: "20px 0px",
                flexDirection: "column",
                cursor: "pointer",
                backgroundColor: "white",
                borderRadius: "10px",
                justifyContent: "space-between",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
              }}
            >
              {/* <Link
                href={"/blog/" + blogs[relBlogs && blogs.length ? 1 : 0]?.slug}
              > */}
              <Grid
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  window.open(
                    "https://gromo.in/blog/" +
                      blogs[relBlogs && blogs.length ? 1 : 0]?.slug
                  );
                }}
              >
                <Image
                  src={blogs[relBlogs && blogs.length ? 1 : 0]?.feature_image}
                  width={376}
                  height={190}
                  style={{
                    height: "190px",
                    width: "376px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                  loading="lazy"
                  alt={
                    blogs[relBlogs && blogs.length ? 1 : 0]?.custom_excerpt ||
                    "GroMo"
                  }
                />
              </Grid>
              <Grid
                style={{
                  margin: "auto",
                  padding: 8,
                }}
              >
                <Typography  sx={{ textAlign: "left" }}>
                  {blogs[relBlogs && blogs.length ? 1 : 0]?.primary_tag
                    ? blogs[
                        relBlogs && blogs.length ? 1 : 0
                      ]?.primary_tag.name.toUpperCase()
                    : "Saving Account"}
                </Typography>
                <Typography
                 
                  fontWeight="bold"
                  sx={{
                    textAlign: "left",
                    fontSize: { xs: "18px", md: "24px" },
                    lineHeight: { xs: "30px", md: "36px" },
                    fontWeight: 700,
                  }}
                >
                  {blogs[relBlogs && blogs.length ? 1 : 0]?.title}
                </Typography>
              </Grid>
              {/* </Link> */}
            </Grid>
          ) : null}
        </Grid>
        {relBlogs && blogs.length ? null : (
          <Link href={"https://gromo.in/blog/"}>
            <Typography
          
              textAlign="center"
              padding="8px"
              color="#4D71D0"
              sx={{
                cursor: "pointer",
                background: "white",
                width: "fit-content",
                margin: "auto",
                fontSize: "14px",
                padding: "8px 16px",
                boxShadow: "0px 1px 4px 0 lightgrey",
                borderRadius: "8px",
              }}
            >
              see more blogs <EastIcon />
            </Typography>
          </Link>
        )}
      </Grid>
    </Grid>
  );
}

export default Blogs;
